import CertifikatesData from "../../data/CertificatesData";
// import data from "../../data/index.json"


const Skills = () => {
  return <div className="skills-section" id="mySkills">
         {/* <h2 className="skills-section-heading">Certificates</h2> */}
         
      {
        
        CertifikatesData.map( (oneSkills) => {
          const {id, image, title} = oneSkills

        return <div className="skills-container" key={id} id="skills">

          
          <div className="skills-card">
          <div className="skills-section-image">
            <img src={image} alt="" />
          </div>
          <h4 className="skills-section-title">{title}</h4>

          </div>
          </div>

        })
      }
  </div>
}
 



// export default function Skills() {
//   return(
//     <section className="skills-section"
//     id="mySkills">
//       <div className="portfolio-container">
//         <p className="section-title">My Skills</p>
//         <h2 className="skills-section-heading">My Certificates</h2>
//       </div>
//       <div className="skills-section-container">
//         {data?.skills?.map((item, index) => (
//           <div key={index}
//           className="skills-section-card">
//             <div className="skills-section-img">
//               <img src={item.src} alt="Certificate"/>
//             </div>
//             <div className="skills-section-card-content">
//               <h3 className="skills-section-title">{item.title}</h3>
//             </div>
//           </div>
        
//         ))}
//       </div>
//     </section>
//   );
// }
export default Skills