import { NavLink } from "react-router-dom"
import data from "../../data/PortfolioData"




export default function Portfolio() {
  return (
  <section className="portfolio-section" id="myPortfolio">
    <div className="portfolio-container-box">
      {/* <div className="portfolio-title">
      <h2>My project</h2>
      </div> */}
      
    </div>

    <div className="portfolio-section-container">
     { data.map((oneProject)=>{
      const {id, img, title, description, view} = oneProject
      return <div className="portfolio-card" key={id}>
        <div className="portfolio-section-img">
          <img src={img} alt="" />
        </div>
        <div className="portfolio-section-title">
          <h3>{title}</h3>
        </div>
        <div className="text-md">
          <p>{description}</p>
        </div>
        <div>
        <div className="btn">
          <NavLink className="btn btn-project" to={view} target="_blank">View projct</NavLink>
        </div>
      </div>

      </div>
      
     })}

    </div>
  </section>
    
 ) 
}

