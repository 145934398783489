import foto from "../../data/img/img_me.jpg"
export default function HeroSection() {
  return (
    <section id="heroSection" className="hero-section">
      <div className="hero-section-content-box">
        <div className="hero-section-content">
          <p className="section-title">Hi, I'm Jakub.</p>
          <h1 className="hero-section-title">
            <span className="hero-section-title-color">React</span> {" "}
            <br />
            Developer
          </h1>
          <p className="hero-section-description">
            Custom React app creation. For your business and private purposes.
           <br />
            I will create a web solution for you.
          </p>
        </div>
        {/* <button className="btn btn-primary">Get In Touch</button> */}
      </div>
      <div className="hero-section-img">
        <img src={foto} alt="my foto" />
      </div>
    </section>
  )
}