import project1 from "./img/portfolio/project1.png"
import project2 from "./img/portfolio/project2.png"
import project3 from "./img/portfolio/project3.png"
import project4 from "./img/portfolio/project4.png"
import project5 from "./img/portfolio/project5.png"
import project6 from "./img/portfolio/project6.png"
import project7 from "./img/portfolio/project7.png"
import project8 from "./img/portfolio/project8.png"


const PortfolioData =[
  {
    id: 1,
    img: project1,
    title: "Cyklo web",
    description: "Website about cycling activities created with PHP. My first website.",
    view: "/old/cykloWeb/index.php",
  },
  {
    id: 2,
    img: project2,
    title: "Healty Live",
    description: "A website dedicated to a healthy lifestyle. Second website. ",
    view: "/old/index.html",
  },
  {
    id: 3,
    img: project3,
    title: "Album app",
    description: "React application. Image + description. Overview of activities.",
    view: "https://activity-album.netlify.app",
  },
  {
    id: 4,
    img: project4,
    title: "Slider images",
    description: "React slider images",
    view: "https://slider-activities.netlify.app",
  },
  {
    id: 5,
    img: project5,
    title: "ToDo App",
    description: "ToDo app v React a firebase",
    view: "https://jl-todo-app-firebase.netlify.app",
  },
  {
    id: 6,
    img: project6,
    title: "Weather App",
    description: "Weather App. Api weather Reactu.",
    view: "https://the-jl-weather.netlify.app",
  },

  {
    id: 7,
    img: project7,
    title: "Booking page",
    description: "Clone booking website for PC",
    view: "https://jl-booking-pc-page.netlify.app/"
  },
  {
    id: 8,
    img: project8,
    title: "Personal web",
    description: "My first portfolio web",
    view: "https://personal-web-jl.netlify.app/"
  }

]
export default PortfolioData