
import { Link } from "react-scroll"

import { FaBars, FaTimes } from "react-icons/fa"
import logo from "../../data/img/logoBlack.png"
import { useState } from "react"

export default function Navbar() {

  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)
//   průhlednost pásu hlavičky při scrolování
  // const [color, setColor] = useState(false)
  // const changeColor = () => {
  //     if (window.scrollY >= 10) {
  //         setColor(true)
  //     }else{
  //         setColor(false)
  //     }
  // }

  // window.addEventListener("scroll", changeColor)


  return (
    <nav className='navbar'>
      <img src={logo} alt="" className='logo'/>
      <div className="navbar-items">
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li>
            <Link
            className="navbar-content"
            to="heroSection"
            >
            Home
            </Link>
          </li>
          <li>
            <Link
            className="navbar-content"
            to="skills"
            >
            Skills
            </Link>
          </li>
          <li>
            <Link
            className="navbar-content"
            to="myPortfolio"
            >
            Projects
            </Link>
          </li>
          <li>
            <Link
            className="navbar-content"
            to="Contact"
            >
            Contact
            </Link>
          </li>
        </ul>
      </div>
      <div className="nav-ico" onClick={handleClick}>
        {click ? (<FaTimes />) : (<FaBars />)}
        
    </div>
    </nav>
  )
}
