import { useRef, useState } from "react"
import emailjs from '@emailjs/browser';


const Contact = () => {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

const formData = useRef()

  const formSubmit = (event) => {
    event.preventDefault()
    alert("Zpráva odeslána.")
    
    


    emailjs.sendForm('service_tskcz2h','template_fax6s6w', formData.current, 'vCKasfkoFrIpQf6VQ')
    .then(result =>{
      console.log(result.text)
    }, (error) =>{ 
      console.log(error.text)
    })

   setName("")
   setEmail("")
   setMessage("")


  }

  return (
    <div className="contact" id="Contact">
    <div  className="form" >
        {/* <h1 className="contact-heading">Contact</h1> */}
        {/* <h2 className="contact-email"></h2> */}
        <form ref={formData}  onSubmit={formSubmit}>
            <input
             type="text" 
             placeholder="Jméno"
             name="name" 
             value={name}
             onChange={ (event) => setName(event.target.value)}
            />
           
            <input
             type="email" 
             placeholder="Email"
             name="email" 
             value={email}
             onChange={ (event) => setEmail(event.target.value)}
            />
            {/* <label>Předmět</label>
            <input type="text" name="subject" value={data.subject} onChange={formChange} /> */}
            
            <textarea
             placeholder="Zpráva"
             rows="16"  
             name="message" 
             value={message}
             onChange={ (event) => setMessage(event.target.value)}
            />
            <button className="btn btn-primary" value="Send">Send message</button>
        </form>
    </div>
  </div>
  )
}

export default Contact