import { FaHome, FaMailBulk, FaPhone, FaLinkedin, FaGithubSquare } from "react-icons/fa"

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left">

          <div className="location">
            <FaHome className="ico"/>
            <div>
              <p>Czech Budweis</p>
              <p>Czechia</p>
            </div>
          </div>

          <div className="mail">
            <FaMailBulk className="ico" />
            <div>
              <p>kuba.le@outlook.cz</p>
            </div>
          </div>
          
          {/* <div className="phone">
            <FaPhone className="ico" />
            <p>+042 *********</p>
          </div> */}
         
          
        </div>

        <div className="right">
        <h4>About me</h4>
        
        <p>My name Jakub.<br />I create a complete web solution for your company.</p>
        <div className="social">
          <a href="https://www.linkedin.com/in/jakub-ledvina-956744255" target="_blank"><FaLinkedin className="social-ico" /></a>
          <a href="https://github.com/KidneyJacob?tab=repositories" target="_blank"><FaGithubSquare className="social-ico"/></a>
        </div>
        <div className="copy">
          <p>&copy; 2024 jales.cz</p>
        </div>
        </div>

      </div>
      </div>
  )
}
