import python from "./img/certificats/python.png"
import kotlin from "./img/certificats/kotlin.png"
import html from "./img/certificats/html.png"
import responziv from "./img/certificats/responziv.png" 

const CertifikatesData = [
  {
    id: 1,
    image: python,
    title: "Certificate basic Python",
  },
  {
    id: 2,
    image: kotlin,
    title: "Certificate basic Kotlin",
  },
  {
    id: 3,
    image: html,
    title: "Certificate HTML website"
  },
  {
    id: 4,
    image: responziv,
    title: "Certificate responsive website"
  },
]

export default CertifikatesData