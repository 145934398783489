import Contact from "../Contact";
import Footer from "../Footer";
import HeroSection from "../HeroSection";
import Portfolio from "../Portfolio";
import Skills from "../Skills";





export default function Home() {
  return(
    <> 

    <HeroSection />
    <Skills />
    <Portfolio />
    <Contact/>
    <Footer />
    </>
  )
}